import React from "react";
import { observer } from "mobx-react-lite";

import MembersTopNavHorizontal from "./MembersTopNavDesktop";
import MembersTopNavVertical from "./MembersTopNavMobile";

import "./nav.css";

function MembersTopNav() {

    return (
            <div className="nav-container">
                <MembersTopNavHorizontal />

                <MembersTopNavVertical />
            </div>
        );
}

export default observer(MembersTopNav);