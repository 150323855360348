import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import IJournalEntryBasic from "../../../../app/models/journal-entries/IJournalEntryBasic";


interface IProps {
    journalEntry: IJournalEntryBasic;
}

const JournalEntryListItem : React.FC<IProps> = ({ journalEntry }) => {
    return (
        <div className="list">
            <div className="item detailed basic-details size-0">
                <div className="custom-heading">
                    <Link to={`/journal-entries/${journalEntry.id}`}>{journalEntry.name}</Link>
                </div>
                <div>
                    {journalEntry.createdDateTimeText}
                </div>
            </div>
        </div>);
};

export default observer(JournalEntryListItem);