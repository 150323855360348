import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { FaArrowLeft } from "react-icons/fa";

import defaults from "../../../../app/common/constants/defaults";
import selectHelper from "../../../../app/common/helpers/selectHelper";
import { useStore } from "../../../../app/stores/store";

import JournalEntryFormSetup from "./JournalEntryFormSetup";
import Loading from "../../../../app/layout/Loading";
import MainSection from "../../../../app/layout/common-components/MainSection";
import TemplateDropdownFormBasic from "../../templates/forms/template-dropdown/TemplateDropdownFormBasic";


const JournalEntryFormOuter = () => {
    
    const params = useParams();
    
    const [ searchParams ] = useSearchParams();
    var searchTemplateId = searchParams.get("templateId");

    const navigate = useNavigate();

    const { templateStore, journalEntryStore } = useStore();

    const options = selectHelper.mapOptions(templateStore.templates);

    const [selectedTemplateId, setSelectedTemplateId] = useState("");

    const handleTemplateSelection = (templateId: string) => setSelectedTemplateId(templateId);

    useEffect(() => {

        if (params.id) {
            journalEntryStore.loadJournalEntry(params.id!);
            return;
        }

        journalEntryStore.clearSelectedJournalEntry();

        if (!selectedTemplateId && searchTemplateId) {
            setSelectedTemplateId(searchTemplateId);
            templateStore.loadTemplate(searchTemplateId);
            return;
        }

        if (!selectedTemplateId) {
            templateStore.loadTemplates();
        }

        if (selectedTemplateId && selectedTemplateId !== defaults.EMPTY_GUID && !templateStore.template?.id) {
            templateStore.loadTemplate(selectedTemplateId);
            return;
        }
        
        if (templateStore.template?.id) {
            journalEntryStore.loadJournalEntryFromTemplate(templateStore.template?.name, templateStore.template?.questions);
        }

    }, [
        templateStore, 
        journalEntryStore, 
        selectedTemplateId, 
        templateStore.template?.id, 
        params.id,
        searchTemplateId,
    ]);

    return (<div>

        { (journalEntryStore.loading) && <Loading />}

        <MainSection
            name={journalEntryStore.journalEntry?.id ? "Update entry" : "New entry"}
            previousSectionLink={journalEntryStore.journalEntry?.id ? `/journal-entries/${journalEntryStore.journalEntry?.id}` : "/journal-entries"}
        >
            <>
                {!journalEntryStore.journalEntry && !selectedTemplateId && <TemplateDropdownFormBasic
                        redirectUrl="from-journal-entries"
                        options={options}
                        handleSelection={handleTemplateSelection} />}

                {(journalEntryStore.journalEntry || selectedTemplateId) && <div>
                    {selectedTemplateId && <div className="link-button" onClick={() => setSelectedTemplateId("")}><FaArrowLeft /> Previous step</div>}

                    <JournalEntryFormSetup
                        id={journalEntryStore.journalEntry?.id}
                        name={journalEntryStore.journalEntry?.name}
                        sections={journalEntryStore.journalEntry?.sections}
                        create={journalEntryStore.createJournalEntry}
                        update={journalEntryStore.updateJournalEntry}
                        navigate={(url) => navigate(url)} />
                </div>}
            </>
        </MainSection>

    </div>);
}

export default observer(JournalEntryFormOuter);