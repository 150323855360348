import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import CustomButton from "../../../../../app/layout/common-components/CustomButton";


interface IProps {
    setTemplateNameFilter: (templateName: string) => void;
    clearAllFilters: () => void;
}

const TemplateFilters = ({
    setTemplateNameFilter, 
    clearAllFilters,
}: IProps) => {

        const [templateNameValue, setTemplateNameValue] = useState("");
    
        const handleTemplateNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setTemplateNameValue(event.target.value);
            setTemplateNameFilter(event.target.value);
        }
    
        const handleClearAllFilters = () => {
            clearAllFilters();
            setTemplateNameValue("");
        }
        
        return (<div className="filters-container">
            <div className="d-flex flex-row mt-3">
                <input
                    name="templateNameFilter" 
                    placeholder="Filter by template" 
                    className="form-control me-3 w-50" 
                    onChange={handleTemplateNameChange}
                    value={templateNameValue}
                 />
            </div>
    
            <CustomButton
                classes="secondary mt-3"
                text="Clear filters"
                onClick={handleClearAllFilters}
            />
        </div>);
}

export default observer(TemplateFilters);