import { createBrowserRouter, Navigate } from "react-router-dom";

import CookiePolicy from "../../../features/legal/CookiePolicy";
import CustomRedirect from "../common-components/navigation/CustomRedirect";
import HomeMember from "../../../features/member/home/HomeMember";
import Logout from "../../../features/user/Logout";
import NotFound from "../NotFound";
import JournalEntryDashboard from "../../../features/member/journal-entries/dashboard/JournalEntryDashboard";
import JournalEntryDetails from "../../../features/member/journal-entries/details/JournalEntryDetails";
import JournalEntryFormOuter from "../../../features/member/journal-entries/forms/JournalEntryFormOuter";
import PrivacyPolicy from "../../../features/legal/PrivacyPolicy";
import TemplatesDashboard from "../../../features/member/templates/dashboard/TemplatesDashboard";
import TemplateDetails from "../../../features/member/templates/details/TemplateDetails";
import TemplateFormIntermediate from "../../../features/member/templates/forms/TemplateFormIntermediate";
import TemplateFormOuter from "../../../features/member/templates/forms/TemplateFormOuter";
import TermsAndConditions from "../../../features/legal/TermsAndConditions";
import MemberRoot from "../../../features/member/home/MemberRoot";


export const memberRouter = createBrowserRouter([
    {
        path: "/",
        element: <MemberRoot />,
        errorElement: <CustomRedirect to="page-not-found" />,
        children: [
            { index: true, element: <HomeMember /> },

            { path: "page-not-found", element: <NotFound /> },

            { path: "logout", element: <Logout /> },
            { path: "login", element: <Navigate to="/" /> },
            { path: "signup", element: <Navigate to="/" /> },
            
            { path: "cookie-policy", element: <CookiePolicy /> },

            { path: "journal-entries", element: <JournalEntryDashboard /> },
            { path: "journal-entries/new", element: <JournalEntryFormOuter /> },
            { path: "journal-entries/:id", element: <JournalEntryDetails /> },
            { path: "journal-entries/:id/edit", element: <JournalEntryFormOuter /> },
            
            { path: "privacy-policy", element: <PrivacyPolicy /> },

            { path: "templates", element: <TemplatesDashboard /> },
            { path: "templates/new", element: <TemplateFormOuter /> },
            { path: "templates/new/:id", element: <TemplateFormIntermediate /> },
            { path: "templates/:id", element: <TemplateDetails /> },
            { path: "templates/:id/edit", element: <TemplateFormOuter /> },
            
            { path: "terms-and-conditions", element: <TermsAndConditions /> },
        ]
    }
])