import { makeAutoObservable, runInAction } from "mobx";

import IUser from "../models/users/IUser";
import IUserLoginFormValues from "../models/users/IUserLoginFormValues";
import IUserRegistrationFormValues from "../models/users/IUserRegistrationFormValues";

import configNames from "../common/constants/configNames";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";
import { notifyCancelAll } from "../common/helpers/notifications/notificationsHelper";
import { store } from "./store";

export default class UserStore {
    user: IUser | null = null;
    refreshTokenTimeout: any;
    thereWasALoginError: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn () {
        return !!this.user;
    }
    
    get logInError () {
        return !!this.user ? "" : this.thereWasALoginError;
    }

    clearLoginError = () => {
        runInAction(() => {
            this.thereWasALoginError = "";
        });
    }

    login = async (credentials: IUserLoginFormValues, navigate: (url: string) => void) => {

        this.clearLoginError();

        try {
            const user = await ApiHelpers.Account.login(credentials);
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            this.startRefreshTokenTimer(user);

            notifyCancelAll();
            navigate("/");
        } catch(error) {
            this.thereWasALoginError = "Invalid email or password.";
            errorHandler.handleError(error);
        }
    }

    logout = (navigate: (url: string) => void) => {
        this.stopRefreshTokenTimer();
        store.commonStore.setToken(null);
        window.localStorage.removeItem(configNames.TOKEN_NAME);
        this.user = null;
        navigate("/");
    }

    getUser = async () => {
        try {
            const user = await ApiHelpers.Account.currentUser();
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            this.startRefreshTokenTimer(user);
        } catch(error) {
            errorHandler.handleError(error);
        }
    }

    register = async (newUser: IUserRegistrationFormValues, navigate: (url: string) => void) => {

        this.clearLoginError();
        
        try {
            const user = await ApiHelpers.Account.register(newUser);
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            this.startRefreshTokenTimer(user);

            notifyCancelAll();
            navigate("/");
        } catch(error) {


            const registrationErrors = (error as any).response.data.errors;

            if (!registrationErrors) {
                this.thereWasALoginError = "Invalid email or password";
                return;
            }

            const messages = [] as string[];

            for (let key in registrationErrors) {
                messages.push(registrationErrors[key]);
            }

            this.thereWasALoginError = messages.join(", ");
        }
    }

    refreshToken = async () => {
            this.stopRefreshTokenTimer();

            try {
                const user = await ApiHelpers.Account.refreshToken();
                runInAction(() => {
                    this.user= user;
                })
                store.commonStore.setToken(user.token);
                this.startRefreshTokenTimer(user);
            } catch (error) {
                errorHandler.handleError(error);
            }
    }

    private startRefreshTokenTimer(user: IUser) {
        const jwtToken = JSON.parse(atob(user.token.split(".")[1]));
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (30 * 1000);
        this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}