import IEnumDetails from "./helpers/IEnumDetails";

interface IScheduleFrequencies {
    DAILY: IEnumDetails;
    WEEKLY: IEnumDetails;
    FORTNIGHTLY: IEnumDetails;
    MONTHLY: IEnumDetails;
    QUARTERLY: IEnumDetails;
    BIANNUALLY: IEnumDetails;
    ANNUALLY: IEnumDetails;
}


const scheduleTypes: IScheduleFrequencies = {
    DAILY: { name: "Daily", label: "Once everyday", value: 0 },
    WEEKLY: { name: "Weekly", label: "Once every week", value: 1 },
    FORTNIGHTLY: { name: "Fortnightly", label: "Once every 2 weeks", value: 2 },
    MONTHLY: { name: "Monthly", label: "Once every month", value: 3 },
    QUARTERLY: { name: "Quarterly", label: "Once every 3 months", value: 4 },
    BIANNUALLY: { name: "Biannually", label: "Once every 6 months", value: 5 },
    ANNUALLY: { name: "Annually", label: "Once every year", value: 6 },
};


export default scheduleTypes;