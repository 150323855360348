import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { FaCheckCircle } from "react-icons/fa";

import CustomButton from "../../common-components/CustomButton";
import ImageCropper from "./ImageCropper";
import ImageDropZone from "./ImageDropZone";
import ImagePreview from "./ImagePreview";


interface IProps {
    setImageUri: (response: any | undefined) => void;  
    uploadFile: (file: Blob | null) => Promise<string | undefined>;
    uploading: boolean;
}

const ImageUploadSection = ({ setImageUri, uploadFile, uploading }: IProps) => {

    const [files, setFiles] = useState<any>();
    const [cropper, setCropper] = useState<Cropper>();
    const [fileSaved, setFileSaved] = useState(false);

    const onCrop = () => {
        if (!cropper) return;

        cropper.getCroppedCanvas()
            .toBlob(blob => {
                uploadFile(blob).then(response => {
                    setImageUri(response);
                    setFileSaved(true);
                });
            });
    }

    const onCancel = () => {
        setFiles(null);
        files?.forEach((file: any) => URL.revokeObjectURL(file.preview));
    }

    useEffect(() => {
      return () => {
        files?.forEach((file: any) => URL.revokeObjectURL(file.preview))
      }
    }, [files])


        

    return (!(files && files.length) 
            ? <ImageDropZone setFiles={setFiles} />
            : <div className="mb-3">
                <ImageCropper 
                    imagePreview={files[0].preview} 
                    setCropper={setCropper}
                />  
                <ImagePreview />
                {fileSaved
                    ? <div><FaCheckCircle /> File saved</div>
                    : <>
                        <CustomButton
                            text={uploading ? "Uploading ..." : "Upload"} 
                            disabled={uploading}
                            onClick={onCrop} 
                            classes="secondary-outline btn btn-sm" 
                        />
                        <span className="link-button" onClick={onCancel}>Cancel</span>
                    </>}
            </div>);
}

export default observer(ImageUploadSection);