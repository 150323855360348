import { observer } from "mobx-react-lite";
import { Cropper } from "react-cropper";

import "cropperjs/dist/cropper.css";


interface IProps {
    imagePreview: string,
    setCropper: (cropper: Cropper) => void;
}

const ImageCropper = ({ imagePreview, setCropper }: IProps) => {

    return (<>
        <Cropper 
            src={imagePreview}
            style={{ height: 200, width: "20rem" }}
            preview={".img-preview"}
            guides={false}
            viewMode={1}
            rotatable={true}
            autoCropArea={1}
            background={false}
            onInitialized={(cropper) => setCropper(cropper)}
        />
    </>);
}

export default observer(ImageCropper);