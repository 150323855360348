import { makeAutoObservable, runInAction } from "mobx";

import IDashboard from "../models/home/IDashboard";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";

export default class ReminderStore {
    constructor() {
        makeAutoObservable(this);
    }

    dashboardReminders : IDashboard | undefined = undefined;
    loading = false;

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    loadReminders = async () => {
        this.setLoading(true);

        try {
            this.clearReminders();
            
            const dashboardReminders = await ApiHelpers.Reminders.list();

            runInAction(() => {
                this.dashboardReminders = dashboardReminders;
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    clearReminders = () => {
        runInAction(() => {
            this.dashboardReminders = undefined;
        })
    }
}