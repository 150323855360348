import { observer } from "mobx-react-lite";

import IJournalEntryBasic from "../../../../app/models/journal-entries/IJournalEntryBasic";

import { useStore } from "../../../../app/stores/store";

import JournalEntryFilters from "./filters/JournalEntryFilters";
import JournalEntryListItem from "./JournalEntryListItem";
import MainSection from "../../../../app/layout/common-components/MainSection";
import NoResultsFound from "../../../../app/layout/NoResultsFound";


interface IProps {
    journalEntries: IJournalEntryBasic[];
}

const JournalEntryList : React.FC<IProps> = ({ journalEntries }) => {

    const { journalEntryStore } = useStore();

    return (<MainSection
        name="Journal entries"
        newItemLink="/journal-entries/new"
        listContainer={true}
    >
        <>
            <JournalEntryFilters
                clearAllFilters={journalEntryStore.clearJournalEntryFilters}
                setTitleFilter={journalEntryStore.setTitleFilter}
                setJournalEntryDateFromFilter={journalEntryStore.setJournalEntryDateFromFilter}
                setJournalEntryDateToFilter={journalEntryStore.setJournalEntryDateToFilter}
            />

            {!(journalEntries && journalEntries.length)
                ? <NoResultsFound message="No journal entries found" />
                : journalEntries.map(je => <JournalEntryListItem journalEntry={je} />)}  
        </>
    </MainSection>);
};

export default observer(JournalEntryList);