import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import { ListGroup } from "react-bootstrap";

import { useStore } from "../../../../app/stores/store";

import Loading from "../../../../app/layout/Loading";
import MainSection from "../../../../app/layout/common-components/MainSection";
import NoResultsFound from "../../../../app/layout/NoResultsFound";
import TemplateFilters from "./filters/TemplateFilters";

const TemplatesDashboard = () => {

    const { templateStore } = useStore();

    useEffect(() => {
        templateStore.loadTemplates();
    }, [templateStore]);

    return (
        <div>
            {templateStore.loading && <Loading />}

            <MainSection
                name="Templates"
                newItemLink="/templates/new"
                listContainer={true}
            >
                <TemplateFilters
                    setTemplateNameFilter={templateStore.setTemplateNameFilter}
                    clearAllFilters={templateStore.clearTemplateFilters}
                />
                
                {!templateStore.templates.length
                    ? <NoResultsFound message="No templates found" />
                    :  <ListGroup>
                            {templateStore.templates.map(t => <ListGroup.Item action><Link to={`/templates/${t.id}`}>{t.name}</Link></ListGroup.Item>)}
                        </ListGroup>}
            
            </MainSection>
            
        </div>);
}

export default observer(TemplatesDashboard);