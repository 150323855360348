import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import CustomButton from "../../../../../app/layout/common-components/CustomButton";
import DatePicker from "react-datepicker";


interface IProps {
    setTitleFilter: (title: string) => void;
    setJournalEntryDateFromFilter: (journalEntryDateFrom: Date | null) => void;
    setJournalEntryDateToFilter: (journalEntryDateTo: Date | null) => void;
    clearAllFilters: () => void;
}

const JournalEntryFilters = ({ 
    setTitleFilter, 
    setJournalEntryDateFromFilter,
    setJournalEntryDateToFilter,
    clearAllFilters,
 }: IProps) => {

    const [titleValue, setTitleValue] = useState("");
    const [journalEntryDateFrom, setJournalEntryDateFrom] = useState<Date | null>(null);
    const [journalEntryDateTo, setJournalEntryDateTo] = useState<Date | null>(null);

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitleValue(event.target.value);
        setTitleFilter(event.target.value);
    }

    const handleJournalEntryDateFrom = (date: Date) => {
        setJournalEntryDateFrom(date);
        setJournalEntryDateFromFilter(date);
    }

    const handleJournalEntryDateTo = (date: Date) => {
        setJournalEntryDateTo(date);
        setJournalEntryDateToFilter(date);
    }

    const handleClearAllFilters = () => {
        clearAllFilters();
        setTitleValue("");
        setJournalEntryDateTo(null);
        setJournalEntryDateFrom(null);
    }
    
    return (<div className="filters-container">
        <div className="d-flex flex-row mt-3">
            <input
                name="titleFilter" 
                placeholder="Filter by title" 
                className="form-control me-3 w-25" 
                onChange={handleTitleChange}
                value={titleValue}
             />
                
            <div className="me-3 w-25">
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="en-GB"
                    selected={journalEntryDateFrom}
                    onChange={handleJournalEntryDateFrom}
                    placeholderText={"Date from"}
                    className="form-control"
                    isClearable
                />
            </div>
            
            <div className="me-3 w-25">
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="en-GB"
                    selected={journalEntryDateTo}
                    onChange={handleJournalEntryDateTo}
                    placeholderText={"Date to"}
                    className="form-control"
                    isClearable
                />
            </div>
        </div>

        <CustomButton
            classes="secondary mt-3"
            text="Clear filters"
            onClick={handleClearAllFilters}
        />
    </div>);
}

export default observer(JournalEntryFilters);