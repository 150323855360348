import React from "react";
import Spinner from "react-bootstrap/esm/Spinner";

import "./loading.css";

const Loading = () => {
    return <div className="loading-screen">
        <div>
            <Spinner animation="grow" />
        </div>        
    </div>
}

export default Loading;