import { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { useStore } from "../../../../app/stores/store";

import Loading from "../../../../app/layout/Loading";
import JournalEntryList from "./JournalEntryList";

const JournalEntryDashboard = () => {

    const { journalEntryStore } = useStore();

    useEffect(() => {
        journalEntryStore.loadJournalEntries();
    }, [journalEntryStore]);

    return (<>
        {journalEntryStore.loading && <Loading />}

        <JournalEntryList journalEntries={journalEntryStore.journalEntries} />  
    </>);
}

export default observer(JournalEntryDashboard);