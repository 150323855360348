import IDetailsBasic from "../../models/shared/IDetailsBasic";
import { ISelectOption } from "../../models/form-fields/ISelectOption";

import defaults from "../constants/defaults";

interface ISelectHelper {
    mapOptions: (templates: IDetailsBasic[]) => ISelectOption[];
}

const mapOptions = (templates: IDetailsBasic[]): ISelectOption[] => {
    const options: ISelectOption[] = [{ text: "Create without a template", value: defaults.EMPTY_GUID }];

    options.push(...templates.map(t => { 
        return {
            text: t.name,
            value: t.id,
        } as ISelectOption; 
    }));

    return options;
}

const selectHelper: ISelectHelper = {
    mapOptions,
};

export default selectHelper;