import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import { FaCircle } from "react-icons/fa";

import IReminderBasic from "../../../../app/models/home/IReminderBasic";

import "../reminders.css";

interface IProps {
    reminder: IReminderBasic;
}

const ReminderDetails: React.FC<IProps> = ({ reminder }) => {

    const { id, name, dueDate, status } = reminder;

    return (
    <div key={id} className="list reminder">
        <div className="item detailed basic-details size-0">
            <div className="custom-heading">
                <Link to={`/journal-entries/new`}>{name}</Link>
            </div>
            <div>
                <p><FaCircle className={`status-icon ${status}`} /> Due {dueDate}</p>
            </div>
        </div>
    </div>);
}

export default observer(ReminderDetails);