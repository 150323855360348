import React from "react";
import { ErrorMessage, useField } from "formik";
import { observer } from "mobx-react-lite";

import { Field } from "formik";

import { ISelectOption } from "../../models/form-fields/ISelectOption";

import FormValidationError from "./FormValidationError";

interface IProps {
    label?: string;
    name: string;
    required?: boolean;
    placeholderOption?: string;
    options: ISelectOption[],
    errorMessage?: string;
}
const SelectField = (props: IProps) => {

  const [field] = useField(props.name);

  return (
    <>
      <div className="form-group">
          {props.label && <label>{props.label}</label>} 
          <Field component="select" {...field} {...props}>
              <option value="">{props.placeholderOption || "Select an option"}</option>
              {props.options && props.options.map(o => <option key={o.value} {...o}>{o.text}</option>)}
          </Field>
          <ErrorMessage name={props.name} render={() => <FormValidationError errorMessage={props.errorMessage} />} />
      </div>
    </>
  );
};

export default observer(SelectField);