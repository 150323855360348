import React from "react";
import { Link } from "react-router-dom";

import procrastinationReflectionChallenge from "./images/procrastination-reflection-challenge.png"
import selfConfidenceReflectionChallenge from "./images/self-confidence-reflection-challenge.png";
import fearOfFailureReflectionChallenge from "./images/fear-of-failure-reflection-challenge.png";
import meetYourHeroesConfidenceChallenge from "./images/meet-your-heroes-confidence-challenge.png";

import IDetailedListItem from "../../../app/models/lists/IDetailedListItem";

function Challenges() {

    const videos = [
        {
            title: "Meet your heroes confidence challenge",
            description: "This 7-day challenge is to step outside your comfort zone and reach out to 5 people you look up to, your heroes.",
            id: "863fda5b-5906-42a4-a22d-56584886e993",
            backgroundImage: meetYourHeroesConfidenceChallenge,
        },
        {
            title: "Self-confidence reflection challenge",
            description: "This 7-day challenge is all about reflecting on what is holding us back and what we can do to improve our self-confidence.",
            id: "47f0741d-6718-40a0-b057-4eea7e822aff",
            backgroundImage: selfConfidenceReflectionChallenge,
        },
        {
            title: "Fear of failure reflection challenge",
            description: "This 7-day challenge is all about how we can learn from our past failures and make sure the fear of failure doesn't hold us back.'",
            id: "8f938518-04cf-4531-b7df-65304e3465cb",
            backgroundImage: fearOfFailureReflectionChallenge,
        },
        {
            title: "Procrastination reflection challenge",
            description: "This 7-day challenge is all about reflecting on how procrastination affects us and what we can do to beat it.",
            id: "e082c742-0183-450c-ba91-f9f88be3b10c",
            backgroundImage: procrastinationReflectionChallenge,
        },
    ] as IDetailedListItem[];

    return (<div>
        <h1>7-day challenges</h1>

        <div className="list-container">
            {videos.map(v => 
            (<Link key={v.id} to={`/challenges/${v.id}`} className="videos list detailed">
                <div className="item detailed clickable">
                    <div className="thumbnail" style={{backgroundImage: `url("${v.backgroundImage}")`}}>
                    </div>
                    <div className="details">
                        <h3 className="title">{v.title}</h3>
                        <div className="description">{v.description}</div>
                    </div>
                </div>
            </Link>))}
        </div>
    </div>)
}

export default Challenges;