import React, { useState } from "react";

import { observer } from "mobx-react-lite";

import { useField, useFormikContext } from "formik";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

import FormValidationError from "./FormValidationError";

interface IProps {
    label: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    errorMessage?: string;
}

const DatePickerField = (props: Partial<IProps & ReactDatePickerProps>) => {

  const { setFieldValue } = useFormikContext();
  const [field] = useField(props.name!);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  return (
    <div className="form-group"> 
        {props.label && <label>{props.label}</label>}
        <DatePicker
          {...props}
          dateFormat="dd/MM/yyyy"
          locale="en-GB"
          selected={(field.value && new Date(field.value)) || null }
          onChange={value => { setShowErrorMessage(!(!!value)); return setFieldValue(field.name, value); }}
          onBlur={e => setShowErrorMessage(!(!!e.currentTarget.value))}
          placeholderText={props.placeholder}
        />
        {showErrorMessage && <FormValidationError errorMessage={props.errorMessage} />}
    </div> 
  );
};

export default observer(DatePickerField);