import { observer } from "mobx-react-lite";
import { RouterProvider } from 'react-router-dom';

import { guestRouter } from "../../../app/layout/router/guestRouter";


function GuestRoutes() {
    return (<RouterProvider router={guestRouter} />);
}

export default observer(GuestRoutes);