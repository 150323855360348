import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { FieldArray, Form, FormikProps } from "formik";
import { FaArrowDown, FaArrowUp, FaMinus, FaPlus } from "react-icons/fa";

import ITemplate from "../../../../app/models/templates/ITemplate";

import { enumToOptionList } from "../../../../app/common/enums/helpers/enumHelper";
import scheduleTypes from "../../../../app/common/enums/scheduleFrequencies";
import { useStore } from "../../../../app/stores/store";

import CheckboxField from "../../../../app/layout/form-fields/CheckboxField";
import CustomButton from "../../../../app/layout/common-components/CustomButton";
import CustomButtonHeading from "../../../../app/layout/common-components/CustomButtonHeading";
import DatePickerField from "../../../../app/layout/form-fields/DatePickerField";
import DeleteButton from "../../../../app/layout/form-fields/DeleteButton";
import SelectField from "../../../../app/layout/form-fields/SelectField";
import TextInputField from "../../../../app/layout/form-fields/TextInputField";

const TemplateFormInner : React.FC<FormikProps<ITemplate>> = (props) => {

    const navigate = useNavigate();
    
    const { values, isSubmitting, isValid, dirty } = props;

    const { templateStore } = useStore();

    const handleConfirmDelete = async () => {
        await templateStore.deleteTemplate(values.id!);

        navigate("/templates");
    }

    return (<Form className="custom-form">

        <TextInputField name="name" label="Name" type="text" errorMessage="Template name is required" />

        <CheckboxField name="isActive" label="Template is in use" />


        <h2 className="custom-heading">Schedule</h2>

        <SelectField name="scheduleFrequency" label="How often" options={enumToOptionList(scheduleTypes)} /> 

        <DatePickerField name="scheduleStartDate" label="Start date (optional)" /> 
        <DatePickerField name="scheduleFinishDate" label="Finish date (optional)" /> 

        <h2 className="custom-heading">Questions</h2>
        <FieldArray
                name="questions"
                render={arrayHelpers => (

                    <div>
                      {values.questions && values.questions.length > 0 ?
                        <>
                          {(
                          values.questions.map((q, index) => (
                            <div key={index}>
                              <TextInputField name={`questions.${index}.questionText`} label="Question" type="text" />

                              <div className="field-list-buttons">
                                    <div>
                                        <CustomButtonHeading onClick={() => arrayHelpers.remove(index)} classes="m-2">
                                            <FaMinus />
                                        </CustomButtonHeading>

                                        <CustomButtonHeading onClick={() => arrayHelpers.insert(index + 1, "")}>
                                            <FaPlus />
                                        </CustomButtonHeading>
                                    </div>

                                    <div>
                                        {index > 0 &&
                                            <CustomButtonHeading onClick={() => arrayHelpers.swap(index, index - 1)} classes="m-2">
                                                <FaArrowUp />
                                            </CustomButtonHeading>}

                                        {values.questions && index < values.questions.length - 1 && 
                                            <CustomButtonHeading onClick={() => arrayHelpers.swap(index, index + 1)}>
                                                <FaArrowDown />
                                            </CustomButtonHeading>}
                                    </div>
                                </div>
                            </div>)))}

                            <CustomButton
                              text={isSubmitting ? "Saving..." : "Save"}
                              type="submit"
                              disabled={!isValid || !dirty || isSubmitting}
                              classes="submit-button primary"
                            />
                        </> 
                        : (
                          <CustomButton
                            text="Add a question"
                            onClick={() => arrayHelpers.push('')}
                            classes="primary" />)}

                    </div>)} />

        

        {values.id && <DeleteButton handleConfirmDelete={handleConfirmDelete} />}
    </Form>);
}

export default observer(TemplateFormInner);