import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import { useStore } from '../stores/store';

import GuestRoutes from '../../features/guest/home/GuestRoutes';
import Loading from './Loading';
import MemberRoutes from '../../features/member/home/MemberRoutes';

import '../../style/index.css';
import '../../style/forms.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";

function App() {
  const { commonStore, userStore, navigationStore } = useStore();

  useEffect(() => {
      if (commonStore.token) {
          userStore.getUser().finally(() => commonStore.setAppHasLoaded());
      } else {
        commonStore.setAppHasLoaded();
      }
  }, [commonStore, userStore, navigationStore]);

  if (!commonStore.appHasLoaded) return (<Loading />);

  if (!userStore.isLoggedIn) return <GuestRoutes />;
  
  return <MemberRoutes />
}

export default observer(App);