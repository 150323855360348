import { ISelectOption } from "../../../models/form-fields/ISelectOption";

export const enumToOptionList = <T extends {}>(customEnum: T): ISelectOption[] =>
    Object.entries(customEnum).map((e: any) => ({ text: e[1].label, value: e[1].value }));

export const areEqual = (enumValue1?: number, enumValue2?: number) =>
    enumValue1?.toString() === enumValue2?.toString();

export const enumText = <T extends {}>(customEnum: T, value: string | undefined) =>
    enumToOptionList(customEnum).filter(e => e.value === value)[0]?.text; 
    
const enumHelper = {
    areEqual,
    enumToOptionList,
    enumText,
};


export default enumHelper;