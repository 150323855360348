import React, { ReactElement } from "react";

import { observer } from "mobx-react-lite";

interface IProps {
    message?: string;
    children?: ReactElement | ReactElement[];
}

const NoResultsFound: React.FC<IProps> = ({ message, children }) => {
    return <>
        {children ? children : <div className="pt-5">{message || "No results found"}</div>}
    </>
}

export default observer(NoResultsFound);