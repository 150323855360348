import { withFormik } from "formik";
import * as Yup from "yup";

import ITemplate from "../../../../app/models/templates/ITemplate";

import TemplateFormInner from "./TemplateFormInner";

interface IFormProps {
    template: ITemplate | null;
    redirectUrl: string;
    create(props: ITemplate): Promise<string | undefined>;
    update(props: ITemplate): Promise<void>;
    navigate(url: string): void;
}

const TemplateFormSetup = withFormik<IFormProps, ITemplate>({

    mapPropsToValues: props => {
        return {

            id: props?.template?.id || null,

            name: props?.template?.name || null,

            isActive: props?.template?.isActive || false,

            scheduleFrequency: props?.template?.scheduleFrequency || null,

            scheduleStartDate: props?.template?.scheduleStartDate || null,

            scheduleFinishDate: props?.template?.scheduleFinishDate || null,


            questions: props?.template?.questions || null,
            
        } as ITemplate;
    },

    enableReinitialize: true,

    validationSchema: Yup.object({

    }),

    handleSubmit: async (updatedForm, { setSubmitting, props}) => {

        try {
            const apiCall = updatedForm.id ? props.update : props.create;

            await apiCall(updatedForm).then((newTemplateId) => {
                const url = newTemplateId ? `${props.redirectUrl}?templateId=${newTemplateId}` : props.redirectUrl;
                props.navigate(url);
            });

        } catch(error) {
            
        } finally {
            setSubmitting(false);
        }
    }
})(TemplateFormInner);

export default TemplateFormSetup;