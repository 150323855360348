import React from "react";

import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";

import { useStore } from "../../../../app/stores/store";

import MainSection from "../../../../app/layout/common-components/MainSection";
import TemplateFormSetup from "./TemplateFormSetup";

const TemplateFormIntermediate = () => {
    
    const params = useParams();
    const navigate = useNavigate();
    
    const { templateStore } = useStore();

    const area = params.id?.replace("from-", "");
    const redirectUrl = area ? `/${area}/new` :  "/templates";

    return (
        <MainSection
            name="New template"
            previousSectionLink={redirectUrl}
        >
            <TemplateFormSetup
                template={templateStore.template}
                redirectUrl={redirectUrl}
                create={templateStore.createTemplate}
                update={templateStore.updateTemplate}
                navigate={(url) => navigate(url)} />
        </MainSection>);
}

export default observer(TemplateFormIntermediate);