import React from "react";
import { observer } from "mobx-react-lite";

import { Field, useField } from "formik";

interface IProps {
    name: string;
}
const TextInputField = (props: IProps) => {

  const [field] = useField(props.name);

  return (
    <>
          <Field {...field} type="hidden" />
    </>
  );
};

export default observer(TextInputField);