import axios, { AxiosResponse } from 'axios';

import ICheckIn from '../models/check-ins/ICheckIn';
import ICheckInBasic from '../models/check-ins/ICheckInBasic';
import IDashboard from '../models/home/IDashboard';
import IJournalEntry from '../models/journal-entries/IJournalEntry';
import IJournalEntryBasic from '../models/journal-entries/IJournalEntryBasic';
import IDetailsBasic from '../models/shared/IDetailsBasic';
import ITemplate from '../models/templates/ITemplate';
import IUser from '../models/users/IUser';
import IUserLoginFormValues from '../models/users/IUserLoginFormValues';
import IUserRegistrationFormValues from '../models/users/IUserRegistrationFormValues';

import { store } from "../stores/store";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
  const token = store.commonStore.token;
  
  if (token && config.headers) {
    config.headers.Authorization = `bearer ${token}`;
  }

  return config;
});

const responseBody = (response: AxiosResponse) => response.data;
const ACCOUNT_ENDPOINT = "account";
const CHECK_IN_LIST_ENDPOINT = "checkinslist";
const CHECK_INS_ENDPOINT = "checkins";
const FILES_ENDPOINT = "files";
const JOURNAL_ENTRY_LIST_ENDPOINT = "journalentrieslist";
const JOURNAL_ENTRY_ENDPOINT = "journalentries";
const REMINDERS_ENDPOINT = "reminders";
const TEMPLATE_LIST_ENDPOINT = "templateslist";
const TEMPLATES_ENDPOINT = "templates";

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
  postForm: (url: string, formData: FormData) => {
    return axios
      .post(url, formData, {
        headers: { 'Content-type': 'multipart/form-data' }
      })
      .then(responseBody);
  }
};

const Account = {
  currentUser: () => requests.get<IUser>(`/${ACCOUNT_ENDPOINT}`),
  login: (user: IUserLoginFormValues) => requests.post<IUser>(`/${ACCOUNT_ENDPOINT}/login`, user),
  register: (user: IUserRegistrationFormValues) => requests.post<IUser>(`/${ACCOUNT_ENDPOINT}/register`, user),
  refreshToken: () => requests.post<IUser>(`/${ACCOUNT_ENDPOINT}/refreshToken`, {}),
};

const CheckIns = {
  list: (): Promise<ICheckInBasic[]> =>
    axios.get(`/${CHECK_IN_LIST_ENDPOINT}`).then(responseBody),
  details: (id: string) => requests.get(`/${CHECK_INS_ENDPOINT}/${id}`),
  create: (checkIn: ICheckIn) => requests.post(`/${CHECK_INS_ENDPOINT}`, checkIn),
  update: (checkIn: ICheckIn) =>
    requests.put(`/${CHECK_INS_ENDPOINT}/${checkIn.id}`, checkIn),
  delete: (id: string) => requests.del(`/${CHECK_INS_ENDPOINT}/${id}`),
};

const Files = {
  upload: (folder: string, formData: FormData) => requests.postForm(`/${FILES_ENDPOINT}/${folder}`, formData),
  delete: (folder: string, fileName: string) => requests.del(`/${FILES_ENDPOINT}/${folder}/${fileName}`),
};

const JournalEntries = {
  list: (): Promise<IJournalEntryBasic[]> => axios.get(JOURNAL_ENTRY_LIST_ENDPOINT).then(responseBody),
  details: (id: string) => requests.get(`/${JOURNAL_ENTRY_ENDPOINT}/${id}`),
  create: (journalEntry: IJournalEntry) => requests.post(`/${JOURNAL_ENTRY_ENDPOINT}`, journalEntry),
  update: (journalEntry: IJournalEntry) =>
    requests.put(`/${JOURNAL_ENTRY_ENDPOINT}/${journalEntry.id}`, journalEntry),
  delete: (id: string) => requests.del(`/${JOURNAL_ENTRY_ENDPOINT}/${id}`),
}

const Reminders = {
  list: (): Promise<IDashboard> => axios.get(REMINDERS_ENDPOINT).then(responseBody),
}

const Templates = {
  list: (): Promise<IDetailsBasic[]> => axios.get(TEMPLATE_LIST_ENDPOINT).then(responseBody),
  details: (id: string) => requests.get(`/${TEMPLATES_ENDPOINT}/${id}`),
  create: (template: ITemplate): Promise<string> => requests.post(`/${TEMPLATES_ENDPOINT}`, template),
  update: (template: ITemplate) =>
    requests.put(`/${TEMPLATES_ENDPOINT}/${template.id}`, template),
  delete: (id: string) => requests.del(`/${TEMPLATES_ENDPOINT}/${id}`),
}

const ApiHelpers = {
  Account,
  CheckIns,
  Files,
  JournalEntries,
  Reminders,
  Templates,
};


export default ApiHelpers;