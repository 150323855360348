import { createContext, useContext } from "react";
import CheckInStore from "./checkInStore";

import CommonStore from "./commonStore";
import FileStore from "./fileStore";
import JournalEntryStore from "./journalEntryStore";
import NavigationStore from "./navigationStore";
import ReminderStore from "./reminderStore";
import TemplateStore from "./templateStore";
import UserStore from "./userStore";

interface Store {
    checkInStore: CheckInStore;
    commonStore: CommonStore;
    fileStore: FileStore;
    journalEntryStore: JournalEntryStore;
    navigationStore: NavigationStore;
    reminderStore: ReminderStore;
    templateStore: TemplateStore
    userStore: UserStore;
}

export const store: Store = {
    checkInStore: new CheckInStore(),
    commonStore: new CommonStore(),
    fileStore: new FileStore(),
    journalEntryStore: new JournalEntryStore(),
    navigationStore: new NavigationStore(),
    reminderStore: new ReminderStore(),
    templateStore: new TemplateStore(),
    userStore: new UserStore(),
}

export const StoreContext = createContext(store);

export const useStore = () => {
    return useContext(StoreContext);   
}