import React from "react";
import { observer } from "mobx-react-lite";

interface IProps {
    errorMessage?: string;
}

const FormValidationError: React.FC<IProps> = ({ errorMessage }) => {
    return (<div>
        <small className="header-label-light">{errorMessage || "Please enter a valid input"}</small>
    </div>);
}

export default observer(FormValidationError);