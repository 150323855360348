import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import "./style/custom-button-heading.css";

interface IProps {
    url?: string;
    onClick?: () => void;
    classes?: string;
    children: ReactElement | ReactElement[];
}

const CustomButtonHeading: React.FC<IProps> = ({ url, classes, onClick, children }) => {
    return (<>
        {url && <Link to={url} className={`new-item-button button-heading ${classes}`}>{children}</Link>}
        {onClick && <span className={`link-button ${classes}`} onClick={onClick}>{children}</span>}
    </>);
}

export default observer(CustomButtonHeading);