import React from "react";
import { observer } from "mobx-react-lite";

import ListGroup from "react-bootstrap/esm/ListGroup";

import IQuestion from "../../../../app/models/check-ins/IQuestion";

interface IProps {
    questions: IQuestion[];
}

const TemplateQuestions: React.FC<IProps> = ({ questions }) => {
    return (<>
        <h2 className="custom-heading">Questions</h2>
        
        <ListGroup className="no-border">
            {questions.map(q => (<ListGroup.Item>{q.position} - {q.questionText}</ListGroup.Item>))}
        </ListGroup>
    </>);
}

export default observer(TemplateQuestions);