import React from "react";
import { observer } from "mobx-react-lite";

import { FaRegCalendarAlt, FaRegCheckCircle, FaRegClock, FaRegTimesCircle } from "react-icons/fa";

import ITemplate from "../../../../app/models/templates/ITemplate";

import { enumText } from "../../../../app/common/enums/helpers/enumHelper";
import scheduleTypes from "../../../../app/common/enums/scheduleFrequencies";


interface IProps {
    template: ITemplate;
}

const TemplateSchedule: React.FC<IProps> = ({ template }) => {

    const { isActive, scheduleFrequency, scheduleTimePeriod } = template;

    return (<>
        <h2 className="custom-heading">Schedule</h2>

        {!isActive 

            ? <div className="schedule-settings mb-5">
                <div><span><FaRegTimesCircle /> Template not in use</span></div>
            </div>

            : <div className="schedule-settings mb-5">
                <div><span><FaRegCheckCircle /> Template is in use</span></div>

                <div><FaRegClock /> {enumText(scheduleTypes, scheduleFrequency) || "Not scheduled"}</div>
                {scheduleFrequency !== null && <div><FaRegCalendarAlt /> {scheduleTimePeriod  || "No finish date"}</div>}
            </div>}
    </>);
}

export default observer(TemplateSchedule);