import React from "react";

import { observer } from "mobx-react-lite";

import MainSection from "../../../app/layout/common-components/MainSection";
import ReminderDashboard from "../reminders/dashboard/ReminderDashboard";

function HomeMember () {

    return (<>
        <MainSection
            name="Welcome"
            newItemLink="/journal-entries/new"
        >
            <ReminderDashboard />
        </MainSection>
    </>);
}

export default observer(HomeMember);