import React, { useState } from "react";
import { Link } from "react-router-dom";

import { FaBars } from "react-icons/fa";

import TopNavMenuItems from "./TopNavMenuItems";

import logo from "../../../logo.png";
import "./nav.css";

function TopNav() {

    const [showMenu, setShowMenu] = useState(false);

    return (
            <div>
                <nav className="top-nav horizontal">
                    <Link to="/"><img src={logo} alt="Overcome Restart" /></Link>
                    <TopNavMenuItems />
                </nav>

                <nav className="top-nav vertical">
                    <Link to="/"><img src={logo} alt="Overcome Restart" /></Link>
                    <div className="menu-icon" onClick={() => setShowMenu(!showMenu)}><FaBars /></div>
                    {showMenu && <TopNavMenuItems />}
                </nav>
            </div>
        );
}

export default TopNav;