import React, { useEffect } from "react";

import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";

import { ErrorMessage, Formik, Form, Field } from "formik";
import * as Yup from "yup";


import Alert from "react-bootstrap/esm/Alert";

import logo from "../../logo.png";
import "./login.css";

import { useStore } from "../../app/stores/store";

function Signup () {

    const navigate = useNavigate();

    const { userStore, navigationStore } = useStore();
    
    useEffect(() => {
        navigationStore.setUserIsOnLoginScreen(true);   

        return () => {
            navigationStore.setUserIsOnLoginScreen(false);
        };
    }, [navigationStore]);

    return (
        <div className="login-container">
            <div className="form rounded-corners shadow">
                <Link to="/"><img src={logo} alt="Overcome Restart" /></Link>

                <Formik
                    initialValues={{ firstName: "", lastName: "", email: "", password: "", error: null }}
                    onSubmit={(values, { setErrors }) => userStore.register(values, (url: string) => navigate(url)).catch(error => {
                        setErrors({error: error.message})
                    })}
                    validationSchema={Yup.object({
                        firstName: Yup.string().required("First name is a required field"),
                        lastName: Yup.string().required("Last name is a required field"),
                        email: Yup.string().required().email(),
                        password: Yup.string().required()
                    })}
                >
                    {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                        <Form onSubmit={handleSubmit} autoComplete="off" className="form-fields">

                            <div className="form-group">
                                <p>Create your Overcome Restart account</p>
                                <label>First name</label>    
                                <Field type="text" name="firstName" />
                                <ErrorMessage name="firstName" render={() => <Alert variant="danger">{errors.firstName}</Alert>} />
                            </div> 
                            <div className="form-group">
                                <label>Last name</label>    
                                <Field type="text" name="lastName" />
                                <ErrorMessage name="lastName" render={() => <Alert variant="danger">{errors.lastName}</Alert>} />
                            </div> 
                            <div className="form-group">
                                <label>Email</label>    
                                <Field type="email" name="email" />
                                <ErrorMessage name="email" render={() => <Alert variant="danger">{errors.email}</Alert>} />
                            </div>    
                            <div className="form-group">
                                <label>Password</label>    
                                <Field type="password"  name="password"/>
                                <ErrorMessage name="password" render={() => <Alert variant="danger">{errors.password}</Alert>} />
                            </div>
                            
                            <ErrorMessage
                                name="error"
                                render={() => <Alert variant="danger">{errors.error}</Alert>}
                            />

                            <small className="sign-up-terms">By clicking Agree & Create account, you agree to the Overcome Restart <Link to="/terms-and-conditions">Terms and Conditions</Link>, <Link to="/privacy-policy">Privacy Policy</Link>, and <Link to="/cookie-policy">Cookie Policy</Link>.</small>
                            <button type="submit" disabled={!isValid || !dirty || isSubmitting} className="primary">
                                {isSubmitting ? "Creating account" : "Agree & Create account"}
                            </button>
                            {userStore.logInError && <Alert variant="danger">Sorry, we couldn't create your account because: <strong>{userStore.logInError}</strong><br />Please check your details are correct.<br />Or contact us for help creating your account.</Alert>}
                            <p className="block-text">Already have an account? <Link to="/login" className="login-alternative">Log in instead</Link></p>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default observer(Signup);