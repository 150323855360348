import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import DeleteModal from "../modals/DeleteModal";

interface IProps {
    disabled?: boolean;
    disabledMessage?: string; 
    handleConfirmDelete: () => void;
}

const DeleteButton: React.FC<IProps> = ({ handleConfirmDelete, disabled, disabledMessage }) => {

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDelete = () => {
        setShowDeleteModal(true);
    }
    
    const handleCancelDelete = () => {
        setShowDeleteModal(false);
    }

    if (disabled) {
        return (
            <span
                className="delete-button disabled"
                title={disabledMessage || "This item cannot be deleted"}
                onClick={handleDelete}>
                Delete
            </span>);
    }

    return (
        <>
            <span className="delete-button" onClick={handleDelete}>Delete</span>

            <DeleteModal
                showDeleteModal={showDeleteModal}
                handleCancelDelete={handleCancelDelete}
                handleConfirmedDelete={handleConfirmDelete}></DeleteModal>
        </>);
};

export default observer(DeleteButton);