import React from "react";

import { useParams } from "react-router-dom";

import Badge from "react-bootstrap/esm/Badge";

import IChallenge from "../../../app/models/challenges/IChallenge";

import procrastinationReflectionChallenge from "./images/procrastination-reflection-challenge.png"
import selfConfidenceReflectionChallenge from "./images/self-confidence-reflection-challenge.png";
import fearOfFailureReflectionChallenge from "./images/fear-of-failure-reflection-challenge.png";
import meetYourHeroesConfidenceChallenge from "./images/meet-your-heroes-confidence-challenge.png";

const Details = () => {
    
    const params = useParams();

    const challenges = [
        {
            id: "47f0741d-6718-40a0-b057-4eea7e822aff",
            title: "Self-confidence reflection challenge",
            description: "This 7-day challenge is all about reflecting on what is holding us back and what we can do to improve our self-confidence.",
            imageDownload: selfConfidenceReflectionChallenge,
            days: [
                {
                    id: "cc293cfe-3ac8-44e3-b14b-076ff7dc6e76",
                    day: 1,
                    questions: [
                        {
                            id: "e9a5c2cd-4ec0-4ac1-893b-786e275994bb",
                            title: "If I were more confident, I would encourage myself to ...",
                        },
                    ]
                },
                {
                    id: "cb68e0b6-c367-49f4-9d5f-52dcf9459ffe",
                    day: 2,
                    questions: [
                        {
                            id: "8cbcb503-aeb7-4c5c-a3fc-4c0bef59a3ca",
                            title: "The 3 biggest things holding me back from being confident are ...",
                        },
                    ]
                },
                {
                    id: "eeb636c4-b1cf-418f-bdcd-0cc310c98483",
                    day: 3,
                    questions: [
                        {
                            id: "22d2fa97-711b-4238-afe7-66faa0915504",
                            title: "The last time I felt I was at my most confident was when I ...",
                        },
                    ]
                },
                {
                    id: "ad781a03-b0af-49dd-ab1a-79ec90586f07",
                    day: 4,
                    questions: [
                        {
                            id: "2f30ce60-910d-4036-be0a-7704a7221550",
                            title: "My 3 strongest qualities are ...",
                        },
                    ]
                },
                {
                    id: "9e066b1c-95e4-42a2-b3a9-f96969081e7b",
                    day: 5,
                    questions: [
                        {
                            id: "bcd02e09-b01d-4c4e-9953-4620c50f6d9a",
                            title: "My biggest accomplishment is ...",
                        }, 
                    ]
                },
                {
                    id: "51de39a9-fa41-4cac-ae2d-af4796f84f77",
                    day: 6,
                    questions: [
                        {
                            id: "8a1bb4a9-9d6a-4530-900c-77dc5165b514",
                            title: "If I ever feel self doubt I'll remind myself that I have overcome ...",
                        },
                    ]
                },
                {
                    id: "cad2bee3-982b-498b-b1aa-615a0578250a",
                    day: 7,
                    questions: [
                        {
                            id: "16d79e1e-7f59-4aa1-9872-bd05dd251bce",
                            title: "This week I'll improve my confidence by doing this one thing ...",
                        },
                    ]
                },
            ],
        },
        {
            id: "8f938518-04cf-4531-b7df-65304e3465cb",
            title: "Fear of failure reflection challenge",
            description: "This 7-day challenge is all about how we can learn from our past failures and make sure the fear of failure doesn't hold us back.'",
            imageDownload: fearOfFailureReflectionChallenge,
            days: [
                {
                    id: "f76a4568-fb9e-4b3b-9336-e638080aa3d2",
                    day: 1,
                    questions: [
                        {
                            id: "84ce16ec-5887-4de9-a09d-55c11c313408",
                            title: "The idea of failing at something important makes me feel ... (because ...) ...",
                        },
                    ]
                },
                {
                    id: "46fb4905-f781-49b3-b2d3-6811b0b4663b",
                    day: 2,
                    questions: [
                        {
                            id: "975c9c88-b2ab-456a-9e0e-bfb394c11391",
                            title: "In the past, trying to avoid failure has held me back in these ways ...",
                        },
                    ]
                },
                {
                    id: "6ebf2bc9-e16a-48df-ab8a-db1a0f289855",
                    day: 3,
                    questions: [
                        {
                            id: "04f69a53-dc7c-406b-b069-4ae8c7c41d8d",
                            title: "In the past when I failed, I learnt this about about myself ...",
                        },
                    ]
                },
                {
                    id: "ac502589-5711-4c59-a60f-e6cf9e71b80b",
                    day: 4,
                    questions: [
                        {
                            id: "01f4c473-27a8-462b-9be9-4c6eb57bd3ff",
                            title: "In the future I can learn from my mistakes and failures by doing ...",
                        },
                    ]
                },
                {
                    id: "55c0b042-a72a-495e-ad11-112fccc644f0",
                    day: 5,
                    questions: [
                        {
                            id: "c823726d-ea09-4433-ab25-1f47f125aaab",
                            title: "If a goal is too scary I could do this to make it more manageable ...",
                        },
                    ]
                },
                {
                    id: "9443345b-d7fe-4fd9-8b63-8bf5f6469a1b",
                    day: 6,
                    questions: [
                        {
                                id: "43d527af-b845-4a74-bf1b-e0265c727632",
                            title: "I think risking failure is worth it when ...",
                        },
                    ]
                },
                {
                    id: "93a2cf18-c13c-40ea-a509-8212cdc3f63f",
                    day: 7,
                    questions: [
                        {
                            id: "9fcbc156-7c8e-4226-86f6-e182ef207e27",
                            title: "If I ever feel afraid to go after my goals I will remind myself that ...",
                        },
                    ]
                },
            ],
        },
        {
            id: "e082c742-0183-450c-ba91-f9f88be3b10c",
            title: "Procrastination reflection challenge",
            description: "This 7-day challenge is all about reflecting on how procrastination affects us and what we can do to beat it.",
            imageDownload: procrastinationReflectionChallenge,
            days: [
                {
                    id: "12304162-f856-44e2-b91d-fe614d6296ab",
                    day: 1,
                    questions: [
                        {
                            id: "79436940-43eb-4363-8553-5fa9d54f6f1f",
                            title: "Usually I procrastinate when ...",
                        },
                    ]
                }, 
                {
                    id: "f88fca0f-c0e4-44c7-a785-8c13004731bf",
                    day: 2,
                    questions: [
                        {
                            id: "22261c8d-e72d-464e-8a95-a819a3354a8a",
                            title: "My go-to procrastination distractions are ...",
                        },
                    ]
                }, 
                {
                    id: "917bd919-f804-44a1-9bed-4ac672415c19",
                    day: 3,
                    questions: [
                        {
                            id: "42b38bde-3e14-4820-b693-574943339f09",
                            title: "I can avoid being tempted by my usual distractions by ...",
                        },
                    ]
                }, 
                {
                    id: "3a3970d9-b451-41e5-94f6-118f79e5804a",
                    day: 4,
                    questions: [
                        {
                            id: "bb41cf6b-40da-4e45-817e-71287eb63620",
                            title: "Procrastinating has impacted me in these ways ...",
                        },
                    ]
                }, 
                {
                    id: "47f0158e-d2bb-46be-9449-da7722e47d5a",
                    day: 5,
                    questions: [
                        {
                            id: "314719c7-8782-49e7-8f18-e28687d1e531",
                            title: "I think if I really wanted to stop procrastinating I would have to ...",
                        },
                    ]
                }, 
                {
                    id: "3ffe45f1-68b8-4b7a-aef6-c43707ea3632",
                    day: 6,
                    questions: [
                        {
                            id: "01bb0db0-432b-4c2b-8037-b18398342303",
                            title: "I can make my tasks more fun and achievable for me by ...",
                        },
                    ]
                }, 
                {
                    id: "5881961d-c113-4ea1-9db7-4aba86631cf5",
                    day: 7,
                    questions: [
                        {
                            id: "7ddb2c77-94e0-4a9a-9d3d-73bedd7a5315",
                            title: "The next time I catch myself procrastinating I am going to ...",
                        },
                    ]
                }, 
            ]
        },
        {
            id: "863fda5b-5906-42a4-a22d-56584886e993",
            title: "Meet your heroes confidence challenge",
            description: `This week’s confidence challenge is to step outside your comfort zone and reach out to 5 people you look up to, your heroes. You can call them, text them or message them on social media. This will really help you stretch your confidence muscles.

            It’s perfectly normal to feel nervous when reaching out to people you look up to. But remember people are generally nice and understanding. You can even tell them you’re doing a confidence challenge and they’ll be understanding, it takes courage to meet people when you struggle with confidence.
            
            Try to get to as many people on your list as possible. Don’t be too worried if you don’t get a response, the main objective is to muster the courage to reach out. At the end of each day/meet reflect on what went well and what you can improve the next day.`,
            imageDownload: meetYourHeroesConfidenceChallenge,
            days: [
                {
                    id: "a84366bc-7049-4f81-8611-bcd0be8d69f3",
                    day: 1,
                    questions: [
                        {
                            id: "45dd8e74-ded1-42b9-b026-f27da827a69e",
                            title: "Start off the challenge by writing down 5 heroes you would like to reach out to. For each person prepare either a question or 2 or some points you would like to discuss with them.",
                        },
                    ]
                },
                {
                    id: "1eb7075b-61d5-42f8-b33a-355b11b3ee58",
                    day: 2,
                    questions: [
                        {
                            id: "8a05b21b-1788-4c52-a406-e327bc3a1c81",
                            title: "Reach out to hero number 1. At the end of the day reflect on how it went, what you learnt and what you might do differently the next day.",
                        },
                    ]
                },
                {
                    id: "23ef1661-bc5e-417e-805b-4342c01ecaf8",
                    day: 3,
                    questions: [
                        {
                            id: "43c87ef5-f9e7-41f3-b438-87d9c832573b",
                            title: "Reach out to hero number 2. At the end of the day reflect on how it went, what you learnt and what you might do differently the next day.",
                        },
                    ]
                },
                {
                    id: "a49da0a3-d58a-4d4d-b0f7-3bdbe3d82dfb",
                    day: 4,
                    questions: [
                        {
                            id: "cc95222e-adde-40fd-985e-6b21e3d09f16",
                            title: "Reach out to hero number 3. At the end of the day reflect on how it went, what you learnt and what you might do differently the next day.",
                        },
                    ]
                },
                {
                    id: "53c2ccc3-2deb-4165-ba15-daf7726d82cd",
                    day: 5,
                    questions: [
                        {
                            id: "278c0ade-e810-4ec8-a5e8-530af2f0d6e5",
                            title: "Reach out to hero number 4. At the end of the day reflect on how it went, what you learnt and what you might do differently the next day.",
                        },
                    ]
                },
                {
                    id: "dafe0dfe-14bd-4c2c-8741-842d527ebcdc",
                    day: 6,
                    questions: [
                        {
                            id: "c8056f3b-febd-4e43-86d0-a85afa8c48e9",
                            title: "Reach out to hero number 5. At the end of the day reflect on how it went, what you learnt and what you might do differently the next day.",
                        },
                    ]
                },
                {
                    id: "55d8873f-1560-468f-bc0a-ff184c009c4d",
                    day: 7,
                    questions: [
                        {
                            id: "8605113d-0a59-4426-9884-e1d56f7952d6",
                            title: "Reflect on how the week went, how it felt, and give yourself a treat for tackling a really difficult challenge. You’re awesome.",
                        },
                    ]
                },
            ]
        },
    ] as IChallenge[];

    const selectedChallenge = challenges.find(x => x.id === params.id);

    return (<div>
        <h1>{selectedChallenge?.title}</h1>

        <p>{selectedChallenge?.description}</p>
        <div className="list-container">
            {selectedChallenge && selectedChallenge.days.map(d => 
            (<div key={d.id} className="list medium-length-content">
                <div className="item detailed challenge">
                    <Badge pill bg="primary" className="day-label">
                        Day {d.day}
                    </Badge>
                    <div className="question-list">{d.questions && d.questions.map(q => 
                        <div className="question-details" key={q.id}>
                            <h3 className="title">{q.title}</h3>
                            <div className="description">
                                <textarea></textarea>
                            </div>
                        </div>)}
                    </div>
                    {/* <button className="primary">Save</button> */}
                </div>
            </div>))}
        </div>
    </div>)
}

export default Details;