import React from "react";
import { Link } from "react-router-dom";

function TopNavMenuItems() {
    return (
        <ul>
            <li><Link className="nav-link" to="/learn">Learn</Link></li>
            <li><Link className="nav-link" to="/challenges">Challenges</Link></li>
            <li><Link className="nav-link" to="/login">Log in</Link></li>
        </ul>
    );
}

export default TopNavMenuItems;