import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useDropzone } from "react-dropzone";
import { FaUpload } from "react-icons/fa";

interface IProps {
    setFiles: (files: any) => void;
}

const ImageDropZone = ({ setFiles }: IProps) => {

    const onDrop = useCallback((acceptedFiles: any) => {
        setFiles(acceptedFiles.map((file: any) => Object.assign(file, {
            preview: URL.createObjectURL(file),
        })));
    }, [setFiles]);


    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const getBorderColor = () => isDragActive
            ? "var(--secondary-color-very-very-light)" 
            : "var(--primary-color-very-light)";

    const getTextColor = () => isDragActive
        ? "var(--secondary-color-very-very-light)" 
        : "var(--normal-text-color-very-light)";

    const dzStyles = {
        border: `dashed 3px ${getBorderColor()}`,
        color: getTextColor(),
        cursor: "pointer",
        borderRadius: "5px",
        marginTop: "2rem",
        marginBottom: "2rem",
        padding: "3rem",
        textAlign: "center" as "center",
        height: 200,
        width: "20rem",
    };

    return (
    <>
    <div {...getRootProps()}>
        <div>
            <input {...getInputProps()} />
            <div style={dzStyles}>
                <FaUpload className="fs-1 mb-3" />
                <p>Drag and drop files here <br />or click to select files</p>
            </div>
        </div>
    </div>
    </>);
}

export default observer(ImageDropZone);