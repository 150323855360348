import { Id, ToastContent, ToastOptions, toast } from "react-toastify";

const notify = (
    toastFunction: (content: ToastContent<unknown>, options?: ToastOptions<{}> | undefined) => Id,
    message: string
): Id => {
    return toastFunction(
        message, 
        {
            position: "bottom-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
}

export const notifySuccess = (message: string) => {
    notify(toast.success, message);
};

export const notifyError = (message: string) => {
    notify(toast.error, message);
};

export const notifyInfo = (message: string) => {
    notify(toast.info, message);
};

export const notifyLoading = (message?: string): Id => {
    return notify(toast.loading, message || "Please wait...");
}

export const notifyUpdate = (id: Id, message?: string) => {
    
    toast.update(
        id, { 
            render: message || "All is good", 
            type: "success", 
            isLoading: false ,
            position: "bottom-right",
            autoClose: 3500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
}

export const notifyCancelAll = () => {
    toast.dismiss();
}

const notificationsHelper = {
    notifyError,
    notifyInfo,
    notifySuccess,
    notifyLoading,
    notifyUpdate,
    notifyCancelAll,
};

export default notificationsHelper;