import { makeAutoObservable, runInAction } from "mobx";

import ICheckIn from "../models/check-ins/ICheckIn";
import ICheckInBasic from "../models/check-ins/ICheckInBasic";
import IQuestion from "../models/check-ins/IQuestion";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";

export default class CheckInStore {

    constructor() {
        makeAutoObservable(this);
    }

    checkInRegistry : Map<string, ICheckInBasic> = new Map();
    checkIn : ICheckIn | null = null;
    loading = false;

    get checkIns () {
        return Array.from(this.checkInRegistry.values());
    }

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    loadCheckIns = async () => {
        this.setLoading(true);

        try {
            this.clearCheckIns();
            
            const checkIns = await ApiHelpers.CheckIns.list();
            
            this.clearSelectedCheckIn();

            runInAction(() => {
                checkIns.forEach(ci => this.checkInRegistry.set(ci.id!, ci));
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    loadCheckIn = async (id: string) => {
        this.setLoading(true);

        try {
            const checkIn = await ApiHelpers.CheckIns.details(id);

            runInAction(() => {
                this.checkIn = checkIn;
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    loadCheckInFromTemplate = async (questions: IQuestion[] | undefined) => {
        this.setLoading(true);

        try {
            const templateQuestionsCopy = questions?.map(q => {
                return {
                    position: q.position,
                    questionText: q.questionText }
            }) as IQuestion[];

            const checkIn = { questions: templateQuestionsCopy } as ICheckIn;

            runInAction(() => {
                this.checkIn = checkIn;
            });
            
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    createCheckIn = async (checkIn: ICheckIn) => {
        this.setLoading(true);

        try {
            await ApiHelpers.CheckIns.create(checkIn);
            await this.loadCheckIns();

        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateCheckIn = async (checkIn: ICheckIn) => {
        this.setLoading(true);

        try {
            await ApiHelpers.CheckIns.update(checkIn);
            await this.loadCheckIns();

        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    deleteCheckIn = async (id: string) => {
        this.setLoading(true);

        try {
            await ApiHelpers.CheckIns.delete(id);
            await this.loadCheckIns();
            this.checkInRegistry.delete(id);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    clearSelectedCheckIn = () => {
        this.checkIn = null;
    }

    clearCheckIns = () => {
        this.checkInRegistry.clear();
    }
}