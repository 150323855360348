import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

const MembersTopNavAdditionalMenuItems = () => {
    return (
        <li>
            <Link className="nav-link" to="/logout">Log out</Link>
        </li>
    );
}

export default observer(MembersTopNavAdditionalMenuItems);