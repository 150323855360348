import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import MembersTopNav from "../nav/MembersTopNav";
import Toasts from "../../../app/layout/common-components/notifications/Toasts";

import "../member.css";

const MemberRoot = () => {
    return (<div className="member app-container">

        <MembersTopNav />

        <div className="content">
            <Outlet />
            <Toasts />
        </div>

    </div>);
}

export default observer(MemberRoot);