import { observer } from "mobx-react-lite";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


const Toasts = () => {
    return (<ToastContainer />);
}

export default observer(Toasts);