import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";

import { FaArrowLeft, FaEdit, FaPlus } from "react-icons/fa";

import CustomButtonHeading from "./CustomButtonHeading";

interface IProps {
    name: string;
    previousSectionLink?: string;
    newItemLink?: string;
    updateItemLink?: string;
    classes?: string;
    listContainer?: boolean;
    children: ReactElement | ReactElement[];
}

const MainSection : React.FC<IProps> = ({
    name,
    previousSectionLink,
    newItemLink,
    classes,
    listContainer,
    children,
    updateItemLink,
}) => {
    return (
        <div className={`section-card-container ${listContainer ? "list-container" : ""} ${classes || ""}`}>

            <div className="main-heading">

                {previousSectionLink && <CustomButtonHeading url={previousSectionLink}>
                    <FaArrowLeft />
                </CustomButtonHeading>}

                <h1>{name}</h1>
                
                {newItemLink && <CustomButtonHeading url={newItemLink} classes="right">
                    <FaPlus />
                </CustomButtonHeading>}

                {updateItemLink && <CustomButtonHeading url={updateItemLink} classes="right">
                    <FaEdit />
                </CustomButtonHeading>}
            </div>

            <div className={`rounded-corners ${listContainer ? "" : "section-card background-light shadow p-4"}`}>
                {children}
            </div>
            
        </div>);
}

export default observer(MainSection);