import { withFormik } from "formik";
import * as Yup from "yup";

import IJournalEntry from "../../../../app/models/journal-entries/IJournalEntry";

import JournalEntryFormInner from "./JournalEntryFormInner";

interface IFormProps extends IJournalEntry {
    create: (journalEntry: IJournalEntry) => Promise<void>;
    update: (journalEntry: IJournalEntry) => Promise<void>;
    navigate: (url: string) => void;
}

const JournalEntryFormSetup = withFormik<IFormProps, IJournalEntry>({

    mapPropsToValues: props => {
        return {
            id: props?.id || null,

            name: props?.name || null,

            sections: props?.sections || null,

        } as IJournalEntry;
    },

    enableReinitialize: true,

    validationSchema: Yup.object({

    }),

    handleSubmit: async (updatedForm, { setSubmitting, props }) => {

        try {
            const apiCall = updatedForm.id ? props?.update : props?.create;

            await apiCall(updatedForm).then(() =>  props.navigate("/journal-entries"));

        } catch (error) {
            
        } finally {
            setSubmitting(false);
        }
    } 
})(JournalEntryFormInner)

export default JournalEntryFormSetup;