import { ErrorMessage, Field, useField } from "formik";
import { observer } from "mobx-react-lite";
import FormValidationError from "./FormValidationError";

interface IProps {
    label?: string;
    name: string;
    required?: boolean;
    rows?: number;
    cols?: number;
    placeholder?: string;
    errorMessage?: string;
}

const TextAreaField = (props: IProps) => {
    const [field] = useField(props.name);

    return (
        <div className="form-group">
          {props.label && <label>{props.label}</label>} 
          <Field as="textarea"  {...field} {...props} />
          <ErrorMessage name={props.name} render={() => <FormValidationError errorMessage={props.errorMessage} />} />
      </div>
    );
}

export default observer(TextAreaField);
