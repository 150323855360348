import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import MembersTopNavAdditionalMenuItems from "./MembersTopNavAdditionalMenuItems";
import MembersTopNavMenuItems from "./MembersTopNavMenuItems";

import logo from "../../../logo.png";

function MembersTopNavDesktop() {

    return (
        <nav className="top-nav desktop-devices shadow">
            <Link to="/"><img src={logo} alt="Yodativity" /></Link>
            <ul>
                <MembersTopNavMenuItems />
                <MembersTopNavAdditionalMenuItems />
            </ul>
        </nav>);
}

export default observer(MembersTopNavDesktop);