import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { useStore } from "../../../../app/stores/store";

import NoResultsFound from "../../../../app/layout/NoResultsFound";
import ReminderDetails from "../details/ReminderDetails";
import { Link } from "react-router-dom";

const ReminderDashboard = () => {

    const { reminderStore } = useStore();

    useEffect(() => {
        reminderStore.loadReminders();
    }, [ reminderStore ]);

    const reminders = reminderStore.dashboardReminders;
    
    return (<>
        <h3>Today</h3>
            
        <div className="reminders">
            {!reminders?.remindersToday?.length
                ? <NoResultsFound>
                        <p>Nothing scheduled for today. <br /><Link to="/templates">Schedule something</Link></p>
                    </NoResultsFound>
                : reminders.remindersToday.map(r => <ReminderDetails reminder={r} />)}
        </div>

        <h3>Tomorrow</h3>
        
        <div className="reminders">
            {!reminders?.remindersTomorrow?.length
                ? <NoResultsFound>
                        <p>Nothing scheduled for tomorrow. <br /><Link to="/templates">Schedule something</Link></p>
                    </NoResultsFound>
                : reminders.remindersTomorrow.map(r => <ReminderDetails reminder={r} />)}
        </div>
    
        <h3>Later this week</h3>
        
        <div className="reminders">
            {!reminders?.remindersLaterThisWeek?.length
                ? <NoResultsFound>
                        <p>Nothing scheduled for later this week. <br /><Link to="/templates">Schedule something</Link></p>
                    </NoResultsFound>
                : reminders.remindersLaterThisWeek.map(r => <ReminderDetails reminder={r} />)}
        </div>
    </>);
}

export default observer(ReminderDashboard);