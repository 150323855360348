import { observer } from "mobx-react-lite";

import imagePlaceholder from "../../../assets/image-placeholder.png";

interface IProps {
    src: string,
    alt: string,
}
const CustomImage = ({ src, alt } : IProps) => {
    return (<img 
        src={src} 
        alt={alt}
        className="mt-3 mb-3"
        style={{ 
            minWidth: "10rem",
            minHeight: "10rem",
            backgroundImage: `url(${imagePlaceholder})`,
            backgroundSize: "cover",
        }} 
    />);
}

export default observer(CustomImage);