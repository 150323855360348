import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";

import ImageUploadSection from "../images/image-upload/ImageUploadSection";

interface IProps {
  fileUriField: string;
  fileExtensionField: string;
  uploadFile: (file: Blob | null) => Promise<any>;
  uploading: boolean;
}
const FileUploadFieldDropzone = (props: IProps) => {

  const { setFieldValue } = useFormikContext();

  const handleOnUpload = (res: any) => {
    if (!res) {
      setFieldValue(props.fileUriField, "");
      setFieldValue(props.fileExtensionField, "");

      return;
    }

    setFieldValue(props.fileUriField, res.fileUri);
    setFieldValue(props.fileExtensionField, res.fileExtension);
  }

  return (<ImageUploadSection 
      setImageUri={handleOnUpload} 
      uploadFile={props.uploadFile} 
      uploading={props.uploading}
  />);
};

export default observer(FileUploadFieldDropzone);