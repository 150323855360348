import { observer } from "mobx-react-lite";
import { RouterProvider } from 'react-router-dom';

import { memberRouter } from "../../../app/layout/router/memberRouter";


function MemberRoutes() {
    return (<RouterProvider router={memberRouter} />)
}

export default observer(MemberRoutes);