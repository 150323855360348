import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../app/stores/store";

const LogoutForm = () => {

    const navigate = useNavigate();

    const { userStore } = useStore();
    
    useEffect(() => {
        userStore.logout((url: string) => navigate(url));
    }, [userStore, navigate]);

    return (
        <div>Goodbye!</div>
    )
}

export default observer(LogoutForm);