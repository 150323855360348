import React, { useState } from "react";
import Modal from "react-bootstrap/esm/Modal";
import { FaPlayCircle } from "react-icons/fa";
import overcomingFearOfFailureThumbnail from "./images/overcoming-fear-of-failure-thumbnail.png";
import fearOfFailureChallengeThumbnail from "./images/fear-of-failure-challenge-thumbnail.png";
import whatIsConfidenceThumbnail from "./images/what-is-confidence-thumbnail.png";

import IDetailedListItem from "../../../app/models/lists/IDetailedListItem";



function Videos() {

    const [show, setShow] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");

    const handleClose = () => setShow(false);

    const handleShow = (url: string) => {
        setVideoUrl(url);
        setShow(true); 
    }

    const videos = [
        {
            title: "Understanding fear of failure and learning to overcome it",
            description: "If fear of failure has ever stopped you from going after what you really wanted, this video will be useful to you.",
            id: "https://www.youtube.com/embed/PpNbcgbRkT8?start=5",
            backgroundImage: overcomingFearOfFailureThumbnail,
        },
        {
            title: "What is confidence?",
            description: "In this mini-video we ask, 'what is confidence and how do you get started on improving your confidence?'",
            id: "https://www.youtube.com/embed/_krqpO-ugRM",
            backgroundImage: whatIsConfidenceThumbnail,
        },
        {
            title: "7-day challenge - Fear of failure",
            description: "Fear of failure doesn't have to hold us back. This 7-day challenge is to do some reflection on how we can prepare ourselves to deal with fear of failure in the future.",
            id: "https://www.youtube.com/embed/CByF16p4Swo",
            backgroundImage: fearOfFailureChallengeThumbnail,
        },
    ] as IDetailedListItem[];

    return (<div>
        <h1>Learning videos</h1>

        <div className="list-container">
            {videos.map(v => 
            (<div key={v.id} className="videos list detailed">
                <div onClick={() => handleShow(v.id)} className="item detailed clickable">
                    <div className="thumbnail" style={{backgroundImage: `url("${v.backgroundImage}")`}}>
                        <FaPlayCircle />
                    </div>
                    <div className="details">
                        <h3 className="title">{v.title}</h3>
                        <div className="description">{v.description}</div>
                    </div>
                </div>
            </div>))}

            <Modal show={show} onHide={handleClose} fullscreen="sm-down" size="lg" centered className="video-player-modal">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body style={{textAlign: "center"}}><iframe src={videoUrl} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe></Modal.Body>
            </Modal>
        </div>
    </div>)
}

export default Videos;