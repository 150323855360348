import React from "react";

import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import { Form, Formik } from "formik";

import { ISelectOption } from "../../../../../app/models/form-fields/ISelectOption";

import CustomButton from "../../../../../app/layout/common-components/CustomButton";
import SelectField from "../../../../../app/layout/form-fields/SelectField";

interface IProps {
    options: ISelectOption[];
    handleSelection: (templateId: string) => void;
    redirectUrl: string;
}

const TemplateDropdownFormBasic : React.FC<IProps> = ({ options, handleSelection, redirectUrl }) => {
    return (
        <Formik
            initialValues={{ templateId: "", error: null }}
            onSubmit={({ templateId }) => handleSelection(templateId) }>
            
            {({ handleSubmit, isSubmitting, errors, isValid, dirty, validateForm }) => 
                (<Form onSubmit={handleSubmit} className="custom-form">
                    <SelectField name="templateId" options={options} required={false} />

                    <Link to={`/templates/new/${redirectUrl}`} className="link-button">Create new template</Link>
                    <br />
                    
                    <CustomButton
                        text={isSubmitting ? "Getting ready..." : "Next"}
                        type="submit"
                        disabled={!isValid || !dirty || isSubmitting}
                        classes="submit-button primary" />
                        
                </Form>)}
        </Formik>);
}

export default observer(TemplateDropdownFormBasic);