import { makeAutoObservable, runInAction } from "mobx";

import ISection from "../models/sections/ISection";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";
import { notifyLoading, notifyUpdate } from "../common/helpers/notifications/notificationsHelper";

export default class FileStore {
    constructor() {
        makeAutoObservable(this);
    }

    selectedSection: ISection | undefined = undefined;

    loading = false;

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    setSelectedSection = (section: ISection) => {
        runInAction(() => {
            this.selectedSection = section;
        });
    }

    uploadBlob = async (folder: string, file: Blob | null): Promise<string | undefined> => {
        this.setLoading(true);
        const id = notifyLoading("Uploading file, please wait...");

        try {

            if (!file) throw Error("File is missing");

            const formData = new FormData();

            formData.append("File", file);

            const uri = await ApiHelpers.Files.upload(folder, formData);
            notifyUpdate(id, "Upload complete");

            return uri;
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    upload = async (folder: string, file: File): Promise<string | undefined> => {
        this.setLoading(true);
        const id = notifyLoading("Uploading file, please wait...");

        try {

            const formData = new FormData();

            formData.append("File", file);

            const uri = await ApiHelpers.Files.upload(folder, formData);
            notifyUpdate(id, "Upload complete");
            return uri;
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }
    

    delete = async (folder: string, fileName: string) => {
        this.setLoading(true);

        try {

            await ApiHelpers.Files.delete(folder, fileName);
            
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }
}