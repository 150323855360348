import { makeAutoObservable, runInAction } from "mobx";

import IDetailsBasic from "../models/shared/IDetailsBasic";
import ITemplate from "../models/templates/ITemplate";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";


interface ITemplateFilters {
    templateName: string | null;
}

export default class TemplateStore {

    constructor() {
        makeAutoObservable(this);
    }

    defaultFilters = (): ITemplateFilters => {
        return { 
            templateName: null,
        };
    }

    templateRegistry : Map<string, IDetailsBasic> = new Map();
    template : ITemplate | null = null;
    loading = false;
    filters = this.defaultFilters();

    get templates () {
        return Array.from(this.templateRegistry.values())
            .filter(t => (!this.filters.templateName
                || (t.name?.toLowerCase().includes(this.filters.templateName.toLowerCase()))));
    }

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    setTemplateNameFilter = (templateName: string | null) => {
        runInAction(() => {
            this.filters.templateName = templateName;
        });
    }

    clearTemplateFilters = () => {
        runInAction(() => {
            this.filters = this.defaultFilters();
        });
    }

    loadTemplates = async () => {
        this.setLoading(true);

        try {
            this.clearTemplates();
            
            const templates = await ApiHelpers.Templates.list();
            
            this.clearSelectedTemplate();

            runInAction(() => {
                templates.forEach(t => this.templateRegistry.set(t.id!, t));
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    loadTemplate = async (id: string) => {
        this.setLoading(true);

        try {
            const template = await ApiHelpers.Templates.details(id);

            runInAction(() => {
                this.template = template;
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    createTemplate = async (template: ITemplate) => {
        this.setLoading(true);

        try {
            const newTemplateId = await ApiHelpers.Templates.create(template);
            await this.loadTemplates();

            return newTemplateId;
            
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateTemplate = async (template: ITemplate) => {
        this.setLoading(true);

        try {
            await ApiHelpers.Templates.update(template);
            await this.loadTemplates();

        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    deleteTemplate = async (id: string) => {
        this.setLoading(true);

        try {
            await ApiHelpers.Templates.delete(id);
            await this.loadTemplates();
            this.templateRegistry.delete(id);
            
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    clearSelectedTemplate = () => {
        this.template = null;
    }

    clearTemplates = () => {
        this.templateRegistry.clear();
    }
}