import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import { useStore } from "../../../app/stores/store";

import TopNav from "../nav/TopNav";


const GuestRoot = () => {
    const { navigationStore } = useStore();

    return (<div className={`${navigationStore.userIsOnLogInScreen ? "member" : "guest"} app-container`}>
        {!navigationStore.userIsOnLogInScreen && <TopNav />}

        <div className="guest-section not-logged-in">
            <div style={!navigationStore.userIsOnLogInScreen ? { marginTop: '7em' } :  { paddingTop: '7em' }}>
                <Outlet />
            </div>
        </div>
    </div>);
}

export default observer(GuestRoot);