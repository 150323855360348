import { Link } from "react-router-dom";

import MainSection from "./common-components/MainSection";


const NotFound = () => {
    return <MainSection
        name=""    
        listContainer={true}
    >
        <div className="pt-5 pb-3">
            <p className="fs-2">This page doesn't exist.</p>
            <p>Check the main menu or visit the <Link to="/">home page</Link>.</p>
        </div>
    </MainSection>
};

export default NotFound;