import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import {  useParams } from "react-router-dom";

import { useStore } from "../../../../app/stores/store";

import Loading from "../../../../app/layout/Loading";
import MainSection from "../../../../app/layout/common-components/MainSection";
import NoResultsFound from "../../../../app/layout/NoResultsFound";
import TemplateSchedule from "./TemplateSchedule";
import TemplateQuestions from "./TemplateQuestions";

import "./details.css";

const TemplateDetails = () => {
    
    const params = useParams();

    const { templateStore } = useStore();

    useEffect(() => {
        templateStore.loadTemplate(params.id!);
    }, [templateStore, params.id])

    const template = templateStore.template;

    return (<div>
        {templateStore.loading && <Loading />}

        <MainSection
            name={template?.name || ""}
            previousSectionLink="/templates"
            updateItemLink={`/templates/${params.id}/edit`}
        >
            {!template?.questions?.length
                ? <NoResultsFound message="This template has no questions" />
                : <div className="template">

                    <TemplateSchedule template={template} />
                    <TemplateQuestions questions={template.questions} />
                </div>}
        </MainSection>
    </div>);
}

export default observer(TemplateDetails);