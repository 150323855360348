import { observer } from "mobx-react-lite";


const ImagePreview = () => {

    const style = {
        height: 200, 
        width: "20rem", 
        overflow: "hidden",
    }

    return (<div className="mt-3 mb-3">
            <p>Preview</p>
            <div className={`img-preview`}  style={style}>
                <p>Preview</p>
            </div>
        </div>);
}

export default observer(ImagePreview);