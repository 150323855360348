import React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, useLocation } from "react-router-dom";


interface IProps {
    to: string;
    includePreviousUrl?: boolean;
}

const CustomRedirect: React.FC<IProps> = ({ to, includePreviousUrl }) => {
    const location = useLocation();

    const finalTo = !includePreviousUrl ? to : `${to}?redirect=${location.pathname}`;

    return (<Navigate to={finalTo} replace state={{ location }} />);
}

export default observer(CustomRedirect);