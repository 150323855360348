import React from "react";
import { Link } from "react-router-dom";

function MembersTopNavMenuItems() {
    return (<>
        <li>
            <Link className="nav-link" to="/journal-entries">Journal entries</Link>
        </li>
        <li>
            <Link className="nav-link" to={"/templates"}>Templates</Link>
        </li>
    </>);
}

export default MembersTopNavMenuItems;