import { makeAutoObservable, runInAction } from "mobx";

import IJournalEntry from "../models/journal-entries/IJournalEntry";
import IJournalEntryBasic from "../models/journal-entries/IJournalEntryBasic";
import IQuestion from "../models/check-ins/IQuestion";
import ISection from "../models/sections/ISection";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";
import sectionTypes from "../common/enums/sectionTypes";


interface IJournalEntryFilters {
    title: string | null;
    templateId: string | null;
    journalEntryDateFrom: Date | null;
    journalEntryDateTo: Date | null;
}

export default class JournalEntryStore {
    constructor() {
        makeAutoObservable(this);
    }

    defaultFilters = (): IJournalEntryFilters => {
        return { 
            title: null, 
            templateId: null,
            journalEntryDateFrom: null,
            journalEntryDateTo: null,
        };
    }

    journalEntryRegistry : Map<string, IJournalEntryBasic> = new Map();
    journalEntry : IJournalEntry | null = null;
    loading = false;
    filters: IJournalEntryFilters = this.defaultFilters();

    get journalEntries () {
        
        return Array.from(this.journalEntryRegistry.values())
            .filter(je => (!this.filters.title
                    || (je.name?.toLowerCase().includes(this.filters.title.toLowerCase())))
                && (!this.filters.templateId || je.templateId === this.filters.templateId)
                && (!(this.filters.journalEntryDateFrom && je.createdDateTimeIso)
                    || this.filters.journalEntryDateFrom <= new Date(je.createdDateTimeIso))
                && (!(this.filters.journalEntryDateTo && je.createdDateTimeIso) 
                    || new Date(je.createdDateTimeIso) <=  this.filters.journalEntryDateTo));
    }

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    setTitleFilter = (title: string | null) => {
        runInAction(() => {
            this.filters.title = title;
        });
    }

    setTemplatIdFilter = (templateId: string | null) => {
        runInAction(() => {
            this.filters.templateId = templateId === "All" ? null : templateId;
        });
    }

    setJournalEntryDateFromFilter = (journalEntryDateFrom: Date | null) => {
        runInAction(() => {
            this.filters.journalEntryDateFrom = journalEntryDateFrom;
        });
    }

    setJournalEntryDateToFilter = (journalEntryDateTo: Date | null) => {

        runInAction(() => {
            if(!journalEntryDateTo) {
                this.filters.journalEntryDateTo = journalEntryDateTo;
                return;
            }
            
            this.filters.journalEntryDateTo = new Date(
                journalEntryDateTo.getFullYear(), 
                journalEntryDateTo.getMonth(), 
                journalEntryDateTo.getDate(),
                23, 
                59, 
                59);
        });
    }

    clearJournalEntryFilters = () => {
        runInAction(() => {
            this.filters = this.defaultFilters();
        });
    }

    loadJournalEntries = async () => {
        this.setLoading(true);

        try {
            this.clearJournalEntries();
            
            const journalEntries = await ApiHelpers.JournalEntries.list();
            
            this.clearSelectedJournalEntry();

            runInAction(() => {
                journalEntries.forEach(je => this.journalEntryRegistry.set(je.id!, je));
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    loadJournalEntry = async (id: string) => {
        this.setLoading(true);

        try {
            const journalEntry = await ApiHelpers.JournalEntries.details(id);

            runInAction(() => {
                this.journalEntry = journalEntry;
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    loadJournalEntryFromTemplate = async (name: string | undefined, questions: IQuestion[] | undefined) => {
        this.setLoading(true);

        try {

            const sectionsFromTemplate = questions?.map(q => {

                return {
                    position: q.position,
                    name: q.questionText,
                    sectionType: sectionTypes.Q_AND_A_FORMAT.value,
                } as ISection;    
            });

            const journalEntry = { name, sections: sectionsFromTemplate } as IJournalEntry;

            runInAction(() => {
                this.journalEntry = journalEntry;
            });
            
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    createJournalEntry = async (journalEntry: IJournalEntry) => {
        this.setLoading(true);

        try {
            await ApiHelpers.JournalEntries.create(journalEntry);
            await this.loadJournalEntries();

        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateJournalEntry = async (journalEntry: IJournalEntry) => {
        this.setLoading(true);

        try {
            await ApiHelpers.JournalEntries.update(journalEntry);
            await this.loadJournalEntries();

        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    deleteJournalEntry = async (id: string) => {
        this.setLoading(true);

        try {
            await ApiHelpers.JournalEntries.delete(id);
            await this.loadJournalEntries();
            this.journalEntryRegistry.delete(id);
            
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    clearSelectedJournalEntry = () => {
        this.journalEntry = null;
    }

    clearJournalEntries = () => {
        this.journalEntryRegistry.clear();
    }
}