import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";

import { useStore } from "../../../../app/stores/store";

import Loading from "../../../../app/layout/Loading";
import MainSection from "../../../../app/layout/common-components/MainSection";
import TemplateFormSetup from "./TemplateFormSetup";

const TemplateFormOuter = () => {

    const params = useParams();
    const navigate = useNavigate();
    
    const { templateStore } = useStore();

    useEffect(() => {
        
        if (!params.id) return;

        templateStore.loadTemplate(params.id);
    }, [templateStore, params.id]);

    return (<>
        {templateStore.loading && <Loading />}

        <MainSection
            name={templateStore.template ? templateStore.template?.name || "" : "New template"}
            previousSectionLink={templateStore.template ? `/templates/${templateStore.template.id}` : "/templates"}
        >
            <TemplateFormSetup
                template={templateStore.template}
                redirectUrl="/templates"
                create={templateStore.createTemplate}
                update={templateStore.updateTemplate}
                navigate={(url) => navigate(url)} />
        </MainSection>
    </>);
}

export default observer(TemplateFormOuter);