import { makeAutoObservable, runInAction } from "mobx";

export default class NavigationStore {
    userIsOnLogInScreen = false;

    constructor() {
        makeAutoObservable(this);
    }

    setUserIsOnLoginScreen = (userIsOnLogInScreen: boolean) => {
        runInAction(() => {
            this.userIsOnLogInScreen = userIsOnLogInScreen;
        })
    }
}