import { notifyError } from "./helpers/notifications/notificationsHelper";

const handleError = (error: any) => {
    notifyError(error.message);
}

const errorHandler = {
    handleError
}

export default errorHandler;